import React, {useEffect, useState} from "react";
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {useNavigate, useParams} from "react-router-dom";
import * as actions from "./JobActions";
import * as userActions from ".././users/UsersActions";
import "./Job.scss";
import CardHeader from "../../containers/card/CardHeader";
import Card from "../../containers/card/Card";
import {jobIcon} from "../../common/icons/Icon";
import Loader from "../../common/loader/Loader";
import TagsList from "../../common/lists/TagsList";
import TagModal from "../../common/modals/TagModal";
import _ from "lodash";
import CardBody from "../../containers/card/CardBody";
import {useContextProvider} from "../../context/ContextProvider/ContextProvider";
import FormField from "../../common/formField/FormField";


const validationSchema = Yup.object({
    name: Yup.string().required(),
});

const Job = () => {
    const {slug} = useParams();
    const navigate = useNavigate();
    const [users, setUsers] = useState([]);
    const [mappedValue, setMappedValue] = useState([]);
    const [loading, setLoading] = useState(true);
    const [tag, setTag] = useState(null);
    const {reloadJobs} = useContextProvider()
    const initialValues = {
        name: '',
        jenkins_job_url: '',
        job_users_attributes: [],
        tags_attributes: [],
    }

    const loadUsers = (callback) => {
        userActions.loadUsers({}, (data) => {
            setUsers(data);
            callback()
        })
    }

    const saveJob = (values, callback) => {
        values.job_users_attributes = values.job_users_attributes.map((jobUser) => {
            return {
                id: jobUser.job_id,
                user_id: jobUser.id,
                _destroy: jobUser._destroy
            }
        });

        actions.saveJob(values, () => {
            navigate("/jobs");
        });
    };

    const onCancel = () => {
        navigate("/jobs");
    }

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: saveJob
    });

    const optionsMapper = (option) => ({value: option.id, label: option.username, ...option});

    const selectMapper = (value) =>
        ({
            value: value.id,
            label: value.username,
            ...value
        })
    ;

    const toFormikMapper = (value) => {
        return {
            id: value.id,
            username: value.username,
            job_id: value.job_id,
            _destroy: value._destroy
        }
    };


    const handleChange = (selectedOptions) => {

        const newValue = formik.values.job_users_attributes.map(v => ({
            ...v,
            _destroy: !selectedOptions.some(option => option.value === v.id)
        }));

        const newSelected = selectedOptions
            .filter(option => !newValue.some(v => v.id === option.value))
            .map(toFormikMapper);

        const updatedValues = [...newValue, ...newSelected];

        formik.setFieldValue('job_users_attributes', updatedValues);
    };

    const handleOpenTagModal = () => {
        setTag({name: '', color: '#FFFFFF'});
    }

    const handleSaveTag = (tag) => {
        if (slug) {
            actions.saveJob(_.pick({
                ...formik.values,
                tags_attributes: [...formik.values.tags_attributes, tag]
            }, 'tags_attributes', 'id', 'slug'), (data) => {
                formik.setFieldValue('tags_attributes', data.tags_attributes);
                reloadJobs()
            });
        } else {
            if (tag.index) {
                formik.setFieldValue('tags_attributes', formik.values.tags_attributes.map(
                    (t) => t.index === tag.index ? tag : t
                ));
            } else {
                formik.setFieldValue('tags_attributes', [...formik.values.tags_attributes, {
                    ...tag,
                    index: formik.values.tags_attributes.length
                }]);
            }
        }
        setTag(null);
    }

    const handleDeleteTag = (tag) => {
        if (slug) {
            actions.saveJob(_.pick({
                ...formik.values, tags_attributes: formik.values.tags_attributes.map(
                    (t) => t.id === tag.id ? {...t, _destroy: true} : t
                )
            }, 'tags_attributes', 'id', 'slug'), (data) => {
                formik.setFieldValue('tags_attributes', data.tags_attributes);
                reloadJobs()
            });
        } else {
            formik.setFieldValue('tags_attributes', formik.values.tags_attributes.filter(
                (t) => t.index !== tag.index
            ));
        }
    }

    const handleEditTag = (tag) => {
        setTag(tag);
    }


    useEffect(() => {
        loadUsers(() => {
            if (slug) {
                actions.loadJob(slug, (data) => {
                    formik.setValues(data)
                    formik.setFieldValue('job_users_attributes', data.job_users.reduce((acc, jobUser) => {
                        acc.push({
                            ...jobUser.user,
                            job_id: jobUser.id,
                        });
                        return acc;
                    }, []));
                    setLoading(false);
                });
            } else {
                setLoading(false);
            }
        });
    }, [slug]);

    useEffect(() => {
        if (formik.values.job_users_attributes) {
            setMappedValue(formik.values.job_users_attributes.filter(v => !v._destroy).map(selectMapper));
        }
    }, [formik.values.job_users_attributes])

    return (
        <Card className='job'>
            {
                tag && <TagModal tag={tag}
                                 onCancel={() => setTag(null)}
                                 onSave={handleSaveTag}/>
            }
            <CardHeader
                name={formik.values.name}
                onCancel={onCancel}
                onSave={formik.handleSubmit}
                icon={jobIcon}
                loading={loading}
            />
            {loading ? <Loader/> : <CardBody>
                <form autoComplete="off">
                    <FormField
                        id="name-input"
                        label="Name"
                        name="name"
                        value={formik.values.name}
                        handleChange={formik.handleChange}
                        handleBlur={formik.handleBlur}
                        touched={formik.touched.name}
                        errors={formik.errors.name}
                    />
                    <FormField
                        id="jenkins-job-url-input"
                        label="Jenkins job url"
                        name="jenkins_job_url"
                        value={formik.values.jenkins_job_url}
                        handleChange={formik.handleChange}
                        handleBlur={formik.handleBlur}
                        touched={formik.touched.jenkins_job_url}
                        errors={formik.errors.jenkins_job_url}
                    />
                    <FormField
                        id="job-users-attributes-input"
                        label="Users"
                        type="select"
                        name="job_users_attributes"
                        value={mappedValue}
                        handleChange={handleChange}
                        handleBlur={formik.handleBlur}
                        touched={formik.touched.job_users_attributes}
                        errors={formik.errors.job_users_attributes}
                        options={users.map(optionsMapper)}
                        isMulti
                        handleChangeSelect={handleChange}
                    />
                    <FormField
                        id="job-users-attributes-input"
                        label="Tags"
                        name="tags_attributes"
                        value={formik.values.tags_attributes}
                        handleChange={formik.handleChange}
                        handleBlur={formik.handleBlur}
                        touched={formik.touched.tags_attributes}
                        errors={formik.errors.tags_attributes}
                        renderInput={false}
                    >
                        <TagsList tags={formik.values.tags_attributes}
                                  onAdd={handleOpenTagModal}
                                  onDelete={handleDeleteTag}
                                  onEdit={handleEditTag}
                                  setTags={(tags) => {
                                      formik.setFieldValue('tags_attributes',
                                          tags.map((tag, index) => ({...tag, order: index}))
                                      )
                                  }}
                                  onEnd={() => {
                                      if (slug) {
                                          actions.saveJob(_.pick({
                                              ...formik.values,
                                              tags_attributes: formik.values.tags_attributes
                                          }, 'tags_attributes', 'id', 'slug'), (data) => {
                                              formik.setFieldValue('tags_attributes', data.tags_attributes);
                                              reloadJobs()
                                          });
                                      }
                                  }}
                        />
                    </FormField>
                </form>
            </CardBody>
            }
        </Card>
    );
}

export default Job;
