import React, {useEffect} from "react";
import {Navigate} from "react-router-dom";
import {useContextProvider} from "../../context/ContextProvider/ContextProvider";

const Logout = () => {
    const {setIsAuthenticated} = useContextProvider();

    useEffect(() => {
        localStorage.removeItem('token');
        localStorage.removeItem('admin');
        setIsAuthenticated(false);
    }, [setIsAuthenticated]);

    return <Navigate to={'/login'} replace/>;
}

export default Logout;
