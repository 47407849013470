import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import * as actions from "./BuildActioins";
import "./Build.scss";
import CardHeader from "../../containers/card/CardHeader";
import Button from "../../common/buttons/Button";
import Card from "../../containers/card/Card";
import Loader from "../../common/loader/Loader";
import {buildIcon} from "../../common/icons/Icon";
import CardBody from "../../containers/card/CardBody";
import ReportSection from "../../common/./raportSection/ReportSection";

const Build = () => {
    const {id, jobSlug, tag} = useParams();
    const navigate = useNavigate();
    const [build, setBuild] = useState('');
    const [loading, setLoading] = useState(false);


    useEffect(() => {
        if (id) {
            setLoading(true)
            actions.loadBuild(jobSlug, id, (data) => {
                setBuild(data);
                setLoading(false)
            });
        }
    }, [id]);

    const goBack = () => {
        navigate(`/builds/${jobSlug}/${tag}`);
    }

    const handleDownload = () => {
        actions.downloadBuildFile(id, build.token);
    }


    return (
        <Card className='build'>
            <CardHeader
                goBack={goBack}
                icon={buildIcon}
                loading={loading}
                name={`#${build.position}`}
            />
            <CardBody>
                {loading ? <Loader/> :
                    <div className='content'>
                        <div className="header">
                            <div className='number'>
                                <span>No: </span>
                                <span>#{build.position}</span>
                            </div>
                            <div className='file-button-wrapper'>
                                <div className='file'>
                                    <span>File: </span>
                                    <span>{build.file_name}</span>
                                </div>
                                {build.file_name && <Button color='primary' onClick={handleDownload}>Download</Button>}
                            </div>
                        </div>
                        {build.report && <ReportSection report={build.report}/>}
                    </div>
                }
            </CardBody>
        </Card>
    )
        ;
}

export default Build;
