import React, {useState} from "react";
import "./Scenario.scss";
import * as actions from "./ScenerioActions";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import classnames from "classnames";
import Accordion from ".././accordion/Accordion";
import Step from "./Step";
import _ from "lodash";


const Scenario = ({scenario}) => {
    const [lightboxOpen, setLightboxOpen] = useState(false);
    const [selectedImages, setSelectedImages] = useState([]);

    const openLightbox = (imagesPath, clickedImageIndex) => {
        const loadedImages = [];
        let currentIndex = 0;

        const loadImage = index => {
            if (index < imagesPath.length) {
                actions.loadImage(imagesPath[index].src, imageData => {
                    const base64 = btoa(
                        new Uint8Array(imageData).reduce(
                            (data, byte) => data + String.fromCharCode(byte),
                            '',
                        ),
                    );

                    loadedImages.push(`data:image/jpeg;base64,${base64}`);
                    loadImage(index + 1);
                });
            } else {
                const imagesWithSrc = loadedImages.map((src, index) => ({
                    src,
                    alt: `Image ${index + 1}`
                }));

                [imagesWithSrc[0], imagesWithSrc[clickedImageIndex]] = [imagesWithSrc[clickedImageIndex], imagesWithSrc[0]];
                setSelectedImages(imagesWithSrc);
                setLightboxOpen(true);
            }
        };

        loadImage(currentIndex);
    };


    return (
        <div className='scenario'>
            <>
                <Accordion
                    title={
                        <>
                            <div className='name'>{scenario.name}</div>
                            <div className={classnames(_.lowerCase(scenario.status))}>
                                {_.capitalize(scenario.status)}
                            </div>
                        </>
                    }
                >
                    <div className='body'>
                        <p className='description'>{scenario.description.split("\n").map(l=><>{l} <br/></>)}</p>
                        {scenario.steps.map((step, index) => (
                            <Step key={index} step={step} openLightbox={openLightbox} scenario={scenario}/>
                        ))}
                    </div>
                </Accordion>
                {lightboxOpen && (
                    <Lightbox
                        open={lightboxOpen}
                        close={() => setLightboxOpen(false)}
                        slides={selectedImages}
                    />
                )}
            </>
        </div>)
}

export default Scenario