import {statusText} from "./constants";

export const formatRelativeDate = (dateString) => {
    const date = new Date(dateString);
    const now = new Date();
    const formatter = new Intl.RelativeTimeFormat(undefined, { numeric: 'auto' });

    const diffInSeconds = Math.round((now - date) / 1000);
    const diffInMinutes = Math.round(diffInSeconds / 60);
    const diffInHours = Math.round(diffInMinutes / 60);
    const diffInDays = Math.round(diffInHours / 24);
    const diffInWeeks = Math.round(diffInDays / 7);


    if (diffInSeconds < 60) {
        return formatter.format(-diffInSeconds, 'second');
    } else if (diffInMinutes < 60) {
        return formatter.format(-diffInMinutes, 'minute');
    } else if (diffInHours < 24) {
        return formatter.format(-diffInHours, 'hour');
    } else if (diffInDays < 30) {
        return formatter.format(-diffInDays, 'day');
    } else {
        return formatter.format(-diffInWeeks, 'week');
    }
}

export const formatDuration = (durationInSeconds, averageInSeconds) => {
    const formatTime = (seconds) => {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        return `${hours}:${minutes.toString().padStart(2, '0')}`;
    };

    const durationFormatted = formatTime(durationInSeconds);
    const averageFormatted = formatTime(averageInSeconds);

    return `${durationFormatted} (avg ${averageFormatted})`;
}

export const formatStatus = (status) => {
    return statusText[status];
}

export const createHiddenTokenString = (token) => {
    if (!token) {
        return '';
    }
    return '*'.repeat(token.length);
};

export const userInitials = (name, surname) => {
    if (!name || !surname) {
        return '';
    }
    return `${name.charAt(0)}${surname.charAt(0)}`;
}

export const reverseColor = (background) => {
    const rgb = parseInt(background.slice(1), 16);
    const r = (rgb >> 16) & 0xff;
    const g = (rgb >> 8) & 0xff;
    const b = (rgb >> 0) & 0xff;
    const luma = 0.2126 * r + 0.7152 * g + 0.0722 * b;
    return luma < 128 ? '#fcfcfc' : '#495057';
}