import {useEffect, useRef, useState} from "react";
import * as actions from "./LazyImageActions";
import './LazyImage.scss';

const LazyImage = ({src, alt, imageLabel, ...rest}) => {
    const [shouldLoad, setShouldLoad] = useState(false);
    const [imageData, setImageData] = useState(null);
    const placeholderRef = useRef(null);

    useEffect(() => {
        if (!shouldLoad && placeholderRef.current) {
            const observer = new IntersectionObserver(([{intersectionRatio}]) => {
                if (intersectionRatio > 0) {
                    setShouldLoad(true);
                }
            });
            observer.observe(placeholderRef.current);
            return () => observer.disconnect();
        }
    }, [shouldLoad, placeholderRef]);

    useEffect(() => {
        if (shouldLoad && src) {
            actions.loadImage(src, (data) => {
                const base64 = btoa(
                    new Uint8Array(data).reduce(
                        (data, byte) => data + String.fromCharCode(byte),
                        '',
                    ),
                );
                setImageData("data:;base64," + base64);
            });
        }
    }, [shouldLoad, src]);

    return <>
        {(shouldLoad && imageLabel) ?
            <div className='lazy-image' ref={placeholderRef}>
                {imageData && <p>{imageLabel}</p>}
                {imageData && <img src={imageData} alt={alt} {...rest}/>}
            </div>
            : <div className='empty' ref={placeholderRef}/>
        }
        {
            shouldLoad && !imageLabel
                ? <img src={imageData} alt={alt} {...rest}/>
                : <div ref={placeholderRef}/>
        }

    </>

        ;
};

export default LazyImage;