import React from 'react';
import './Avatar.scss';
import classnames from "classnames";


const Avatar = ({image, size}) => {

    return (
        <div className={classnames("avatar", size)}>
            <img src={image} alt="avatar"/>
        </div>
    )

}

export default Avatar;