import React, {forwardRef} from "react";
import Modal from "./Modal";
import {ReactCrop} from "react-image-crop";
import CancelSaveButtons from "../buttons/CancelSaveButtons";

const CropModal = forwardRef(({
                                  imgSrc,
                                  crop,
                                  onChange,
                                  onClose,
                                  onComplete,
                                  onLoad,
                                  onCancel,
                                  onSave
                              }, ref) => {
    return (
        <Modal
            isOpen={!!imgSrc}
            onClose={onClose}
            className='crop'
        >
            <div className='header'>
                <div>Profile:</div>
                <div>Crop your avatar</div>
            </div>
            {!!imgSrc && (
                <div className='body'>
                    <ReactCrop
                        crop={crop}
                        onChange={onChange}
                        onComplete={onComplete}
                        aspect={1}
                        minHeight={300}
                        minWidth={300}
                        circularCrop
                        keepSelection
                    >
                        <img
                            ref={ref}
                            alt="Crop me"
                            src={imgSrc.url}
                            onLoad={onLoad}
                            className='image'
                        />
                    </ReactCrop>
                </div>
            )}
            <div className='footer'>
                <CancelSaveButtons onCancel={onCancel} onSave={onSave}/>
            </div>
        </Modal>
    )
});

export default CropModal;