import React, {createContext, useContext, useState} from 'react';
import * as actions from './ContextProviderActions';

export const Context = createContext();

export const ContextProvider = ({children}) => {
    const [jobs, setJobs] = useState([]);
    const [isAuthenticated, setIsAuthenticated] = useState(!!localStorage.getItem('token'));
    const [profile, setProfile] = useState();

    const reloadProfile = (callback) => {
        actions.loadProfile((data) => {
            setProfile(data);
            callback && callback(data);
        });
    };

    const reloadJobs = (callback) => {
        actions.loadJobs({}, (data) => {
            setJobs(data);
            callback && callback(data);
        });

    }

    return (
        <Context.Provider value={{
            jobs, setJobs, reloadJobs, isAuthenticated, setIsAuthenticated,
            profile, setProfile, reloadProfile
        }}>
            {children}
        </Context.Provider>
    );
}

export const useContextProvider = () => {
    return useContext(Context);
}