export const statusColors = {
    'SUCCESS': '#5AE8A4',
    'FAILURE': '#FC8182',
    "IN_PROGRESS": "#7DB4FC",
    "PENDING": "#FFD700",
    "CANCELED": "#A0A0A0"
};

export const statusText = {
    'SUCCESS': 'Success',
    'FAILURE': 'Failure',
    "IN_PROGRESS": "In Progress",
    "PENDING": "Pending",
    "CANCELED": "Canceled"
}
