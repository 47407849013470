import axios from "axios";

export const loadBuilds = (jobSlug, params, callback) => {
    const config = {
        url: `jobs/${jobSlug}/builds`,
        params: params
    };

    axios.request(config).then(
        response => {
            callback(response.data);
        }
    )
}

export const deleteBuild = (jobSlug, id, callback) => {
    const config = {
        url: `jobs/${jobSlug}/builds/${id}`,
        method: 'DELETE'
    };

    axios.request(config).then(
        response => {
            callback(response.data);
        }
    )
}

export const loadTags = (jobSlug, callback) => {
    const config = {
        url: `jobs/${jobSlug}/tags`,
        method: 'GET'
    };

    axios.request(config).then(
        response => {
            callback(response.data);
        }
    )
}








