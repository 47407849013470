import axios from "axios";

export const loadJob = (id, callback) => {
    const config = {
        url: `/jobs/${id}`
    }

    axios.request(config).then(
        response => {
            callback(response.data);
        }
    )
}

export const saveJob = (data, callback) => {
    const config = {
        url: data.id ? `/jobs/${data.slug}` : '/jobs',
        method: data.id ? 'PUT' : 'POST',
        data: {
            job: data
        }
    }

    axios.request(config).then(
        response => {
            callback(response.data);
        }
    )
}