import React from "react";
import Icon, {deleteIcon} from "../icons/Icon";
import Button from "../buttons/Button";
import "./DeleteModal.scss";
import Modal from "./Modal";


const DeleteModal = ({resource, name, onClose, onDelete, isOpen}) => {
    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            size="md"
            height='fit-content'
            className='delete'
        >
            <div className='header'>
                <Icon icon={deleteIcon}/>
                {resource && name && <>
                    <div>{name}:</div>
                    <div>{resource.name || resource.username || resource.id}</div>
                </>
                }
                {!resource && name && <div>{name}</div>}
            </div>
            <div className='body'>
                Are you sure, you want to delete?
            </div>
            <div className='footer'>
                <Button
                    color="secondary"
                    onClick={() => onClose()}>
                    Cancel
                </Button>
                <Button
                    onClick={() => onDelete()}
                    color="primary">
                    Delete
                </Button>
            </div>

        </Modal>
    )
        ;
}

export default DeleteModal;