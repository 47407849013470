import axios from "axios";

export const loadUsers = (params, callback) => {
    const config = {
        url: '/users',
        params: params
    };


    axios.request(config).then(
        response => {
            callback(response.data);
        }
    )
}

export const deleteUser = (id, callback) => {
    const config = {
        url: `/users/${id}`,
        method: 'DELETE'
    }

    axios.request(config).then(
        response => {
            callback();
        }
    )
}

