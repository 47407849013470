import React from "react";
import './CancelSaveButtons.scss';
import Button from "./Button";


const CancelSaveButtons = ({onCancel, onSave}) => {
    return <>
        {onCancel && <Button
            color="secondary"
            onClick={() => onCancel()}>
            Cancel
        </Button>}
        {onSave && <Button

            onClick={() => onSave()}
            color="primary">
            Save
        </Button>}
    </>
}
export default CancelSaveButtons;