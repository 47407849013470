import React from 'react';
import classnames from 'classnames';
import Select from 'react-select';
import {selectStyles} from "../styles/selectStyles";
import './FormField.scss';

const FormField = ({
                       label,
                       type = 'text',
                       name,
                       value,
                       handleChange,
                       handleBlur,
                       touched,
                       errors,
                       options = null,
                       isMulti = false,
                       handleChangeSelect,
                       id,
                       autoComplete = 'off',
                       placeholder = '',
                       className = '',
                       children,
                       renderInput = true
                   }) => {
    const isError = touched && errors;

    const renderInputField = () => {
        switch (type) {
            case 'select':
                return (
                    <Select
                        isMulti={isMulti}
                        name={name}
                        value={value}
                        onChange={handleChangeSelect}
                        options={options}
                        onBlur={handleBlur}
                        className={classnames('form-select', className,)}
                        styles={selectStyles}
                    />
                );
            case 'checkbox':
                return (
                    <input
                        id={id}
                        type={type}
                        className={classnames('form-input form-checkbox', className)}
                        name={name}
                        value={value}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                );
            default:
                return (
                    <input
                        id={id}
                        type={type}
                        className={classnames('form-input', className)}
                        name={name}
                        value={value}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        autoComplete={autoComplete}
                        placeholder={placeholder}
                    />
                );
        }
    };

    return (
        <div className="form-field">
            <label htmlFor={id} className={classnames("form-label", {
                'error': isError,
                "checkbox": type === 'checkbox'
            })}>
                {label}
            </label>
            {renderInput && renderInputField()}
            {children}
        </div>
    );
};

export default FormField;
