import React, {useEffect} from "react";
import Dropdown from "./Dropdown";
import {buildIcon} from "../icons/Icon";
import './BuildsDropdown.scss'
import {useContextProvider} from "../../context/ContextProvider/ContextProvider";
import NavbarItem from ".././navbar/NavbarItem";
import {useNavigate} from "react-router-dom";

const BuildsDropdown = ({isSelected}) => {
    const {jobs, reloadJobs} = useContextProvider()
    const navigate = useNavigate();

    const handleItemClicked = (path) => {
        navigate(path);
    }


    useEffect(() => {
        reloadJobs()
    }, [])

    return (
        <Dropdown
            className='builds'
            arrow
            component={
                <NavbarItem icon={buildIcon}
                            text="Builds"
                            path="/builds"
                            isSelected={isSelected}
                            navbarItemRedirect={false}/>
            }>
            <ul className="list">
                {
                    jobs.length > 0 ? jobs.map((job) => {
                            return (
                                <li key={job.slug} className='item'
                                    onClick={() => handleItemClicked(`/builds/${job.slug}/${job.tags.length > 0 ? job.tags[0].name : "Untagged"} `)}>
                                    {job.name}
                                </li>
                            )
                        }) :
                        <li className='item nothing-found'>Nothing found</li>
                }
            </ul>
        </Dropdown>
    )
}

export default BuildsDropdown;