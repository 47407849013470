import axios from "axios";

export const loadImage = (url, callback) => {
    const config = {
        url,
        responseType: 'arraybuffer'
    };

    axios.request(config).then(
        response => {
            callback(response.data);
        }
    )
}
