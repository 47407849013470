import axios from "axios";

export const login = (credentials, callback, onError) => {
    const config = {
        url: '/users/sign_in',
        method: 'POST',
        headers: {
            'noAuth': true
        },
        data: {
            user: credentials
        }
    }
    axios.request(config).then(
        response => {
            callback(response.data, response.headers.authorization);
        }
    ).catch(onError)
}