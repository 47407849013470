import axios from "axios";

export const downloadAvatar = (size, callback) => {
    const config = {
        url: `/profile/picture`,
        method: 'GET',
        responseType: 'blob',
        params: {
            size: size
        }
    };

    axios.request(config).then(
        (response) => {
            callback(response.data);
        }
    );
}