import axios from "axios";
import appConfig from "../config/applicationConfiguration";
import {store} from "../redux/store";
import {appVersionMismatch, updateAppVersion} from "../redux/slices/AppSlice";

export const setupAxios = (navigate) => async dispatch => {
    axios.defaults.baseURL = appConfig.apiUrl;
    axios.defaults.headers['Accept'] = 'application/json';

    axios.interceptors.request.use((config) => {
        if (!config.headers.noAuth) {
            const token = localStorage.getItem('token');
            if (token) {
                config.headers['Authorization'] = token;
            }
        }
        delete config.headers.noAuth;
        return config;
    }, (error) => {
        return Promise.reject(error);
    });

    axios.interceptors.response.use(response => {
        const appVersion = store.getState().app.appVersion;
        const apiVersion = response.headers['app-version']
        if (!appVersion && apiVersion) {
            dispatch(updateAppVersion(apiVersion))
        } else if (appVersion !== apiVersion) {
            dispatch(appVersionMismatch(true))
        }
        return response;
    }, error => {
        if (error.response && error.response.status === 401) {

            localStorage.removeItem('token');
            localStorage.removeItem('admin');

            navigate('/login')
        }
        return Promise.reject(error);
    });
};