import React from "react";
import classnames from "classnames";
import './Button.scss';

const Button = ({children, className, onClick, color, size,}) => {
    return <button className={classnames("button", className, color, size)}
                   onClick={e => {
                       if (onClick) {
                           e.preventDefault();
                           e.stopPropagation();
                           onClick();
                       }
                   }}>
        {children}
    </button>
}
export default Button;