import React, {useEffect} from "react";
import CancelSaveButtons from "../buttons/CancelSaveButtons";
import {useFormik} from "formik";
import Icon, {editIcon} from "../icons/Icon";
import * as Yup from "yup";
import Modal from "./Modal";
import FormField from "../formField/FormField";


const validationSchema = Yup.object({
    name: Yup.string().required(),
    color: Yup.string().required(),
})

const TagModal = ({onCancel, tag, onSave}) => {
    const initialValues = {
        name: '',
        color: '#FFFFFF',
    }

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (values) => {
            onSave(values);
        }
    });

    useEffect(() => {
        if (tag) {
            formik.setValues(tag);
        }
    }, [tag]);

    return (
        <Modal
            isOpen={!!tag}
            onClose={onCancel}
            size="lg"
            height='fit-content'
            className='tag'
        >
            <div className='header'>
                <Icon icon={editIcon}/>
                <div>Tag:</div>
                <div>{tag.name || "New"}</div>
            </div>
            <div className='body'>
                <form autoComplete="off">
                    <FormField
                        id="name-input"
                        label="Name"
                        name="name"
                        value={formik.values.name}
                        handleChange={formik.handleChange}
                        handleBlur={formik.handleBlur}
                        touched={formik.touched.name}
                        errors={formik.errors.name}
                    />
                    <FormField
                        id="color-input"
                        label="Color"
                        name="color"
                        type="color"
                        className='color-input'
                        value={formik.values.color}
                        handleChange={formik.handleChange}
                        handleBlur={formik.handleBlur}
                        touched={formik.touched.color}
                        errors={formik.errors.color}
                    />
                </form>
            </div>
            <div className='footer'>
                <CancelSaveButtons onCancel={onCancel} onSave={formik.handleSubmit}/>
            </div>
        </Modal>
    );
}

export default TagModal;