import React, {useEffect, useRef, useState} from "react";
import * as actions from "./LazyTextDiffActions";
import TextDiff from ".././././textDiff/TextDIff";

const LazyTextDiff = ({fileName, fileUrlActual, fileUrlExpected}) => {
    const [shouldLoad, setShouldLoad] = useState(false);
    const [fileActual, setFileActual] = useState("");
    const [fileExpected, setFileExpected] = useState("");
    const placeholderRef = useRef(null);

    useEffect(() => {
        if (!shouldLoad && placeholderRef.current) {
            const observer = new IntersectionObserver((entries) => {
                if (entries[0].isIntersecting) {
                    setShouldLoad(true);
                }
            });
            observer.observe(placeholderRef.current);
            return () => observer.disconnect();
        }
    }, [shouldLoad]);

    useEffect(() => {
        if (shouldLoad) {
            actions.loadTextFile(fileUrlActual, setFileActual);
            actions.loadTextFile(fileUrlExpected, setFileExpected);
        }
    }, [shouldLoad, fileUrlActual, fileUrlExpected]);

    return shouldLoad && fileActual && fileExpected ? (
        <TextDiff
            fileName={fileName}
            fileActual={fileActual}
            fileExpected={fileExpected}
        />
    ) : (
        <div ref={placeholderRef}/>
    );
};

export default LazyTextDiff;
