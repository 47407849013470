import React, {useEffect, useMemo, useState} from 'react';
import "./Jobs.scss"
import * as actions from "./JobsActions"
import {useNavigate} from "react-router-dom";
import Button from "../../common/buttons/Button";
import Icon, {eyeIcon, eyeSlashIcon, jobIcon} from "../../common/icons/Icon";
import Table from "../../common/tables/Table";
import CardHeader from "../../containers/card/CardHeader";
import {createHiddenTokenString} from "../../common/utils/utils";
import Card from "../../containers/card/Card";
import CardBody from "../../containers/card/CardBody";
import TableActions from "../../common/buttons/TableActions";
import {useContextProvider} from "../../context/ContextProvider/ContextProvider";

const Jobs = () => {
    const navigate = useNavigate();
    const [hiddenTokens, setHiddenTokens] = useState({});
    const admin = localStorage.getItem('admin');
    const [loading, setLoading] = useState(true);
    const {jobs, setJobs} = useContextProvider();

    const toggleTokenVisibility = (id) => {
        setHiddenTokens({...hiddenTokens, [id]: !hiddenTokens[id]})
    };
    const refreshList = () => {
        actions.loadJobs({}, (data) => {
            setHiddenTokens(data.reduce((acc, job) => ({...acc, [job.id]: true}), {}));
            setJobs(data);
            setLoading(false);
        });
    }

    const deleteAction = (job) => {
        actions.deleteJob(job.slug, () => {
            refreshList();
        });
    }

    const editAction = (job) => {
        navigate(`/job/${job.slug}`)
    }

    const handleAddJob = () => {
        navigate(`/job`)
    }

    useEffect(() => {
        refreshList();
    }, []);

    const columns = useMemo(() => [
        {
            Header: 'Name',
            accessor: 'name',
            Cell: ({row}) => (
                <div className='row-name'>
                    <p>{row.original.name}</p>
                </div>)
        },
        {
            Header: 'Api token',
            accessor: 'api_token',
            className: 'api-token',
            Cell: ({row}) => (
                <div className='token-row'>
                    <span>{hiddenTokens[row.original.id] ? createHiddenTokenString(row.original.api_token) : row.original.api_token}</span>
                    {row.original.api_token &&
                        <Button size='icon' onClick={() => toggleTokenVisibility(row.original.id)}>
                            <Icon icon={hiddenTokens[row.original.id] ? eyeIcon : eyeSlashIcon}/>
                        </Button>}
                </div>
            )
        },
        {
            Header: 'Jenkins job url',
            accessor: 'jenkins_job_url'
        },
        {
            Header: 'Options',
            Cell: ({row}) => (
                admin === 'true' &&
                <TableActions row={row.original} name='Job' onEdit={editAction} onDelete={deleteAction}/>

            )
        }
    ], [hiddenTokens]);

    return (
        <Card className="jobs">
            <CardHeader onAdd={admin === 'true' && handleAddJob} icon={jobIcon}/>
            <CardBody>
                <Table columns={columns} data={jobs} loading={loading}/>
            </CardBody>
        </Card>
    );
}

export default Jobs;
