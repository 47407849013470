import axios from "axios";
import appConfig from "../../config/applicationConfiguration";

export const loadBuild = (jobSlug, id, callback) => {
    const config = {
        url: `/jobs/${jobSlug}/builds/${id}`
    }

    axios.request(config).then(
        response => {
            callback(response.data);
        }
    )
}

export const downloadBuildFile = (id, token) => {
    window.open(`${appConfig.apiUrl}/builds/${id}/download_file?token=${token}`, "_blank")
}