import {useEffect} from "react";
import {useDispatch} from "react-redux";
import {setupAxios} from "./axiosSetup";
import {useNavigate} from "react-router-dom";

const AxiosSetup = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(setupAxios(navigate))
    }, [])

    return null
}

export default AxiosSetup;