import React, {useEffect, useState} from "react";
import "./Profile.scss";
import {useFormik} from "formik";
import AvatarUpload from "../../common/avatar/AvatarUpload";
import CardHeader from "../../containers/card/CardHeader";
import * as actions from "./ProfileActions";
import _ from "lodash";
import {useNavigate} from "react-router-dom";
import * as Yup from "yup";
import Card from "../../containers/card/Card";
import {userIcon} from "../../common/icons/Icon";
import Loader from "../../common/loader/Loader";
import FormField from "../../common/formField/FormField";
import {useContextProvider} from "../../context/ContextProvider/ContextProvider";
import Button from "../../common/buttons/Button";

const validationSchema = Yup.object({
    name: Yup.string().required('Name is required'),
    surname: Yup.string().required('Surname is required'),
    password: Yup.string().min(6, 'Must be at least 6 characters'),
    confirmPassword: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match'),
    jenkins_username: Yup.string().required('Jenkins username is required'),
    jenkins_token: Yup.string().required('Jenkins token is required')
});
const Profile = () => {
    const {profile, reloadProfile} = useContextProvider();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [avatarForm, setAvatarForm] = useState(null);
    const admin = localStorage.getItem('admin');

    const resetDatabase = () => actions.resetDatabase(() => navigate("/logout"))

    const saveProfile = (values) => {
        if (!values.change_password && values.id) {
            values = _.omit(values, ['password']);
        }

        if (!values.change_jenkins && values.id) {
            values = _.omit(values, ['jenkins_username', 'jenkins_token']);
        }

        values = _.omit(values, ['change_password', 'change_jenkins', 'confirmPassword', 'username', 'picture_path']);

        if (avatarForm) {
            actions.uploadAvatar(avatarForm, () => {
                setAvatarForm(null)
            });
        }
        actions.saveProfile(values, () => {
            if (avatarForm) {
                actions.uploadAvatar(avatarForm, () => {
                    setAvatarForm(null)
                    reloadProfile();
                    navigate("/");
                });
            } else {
                reloadProfile();
                navigate("/");
            }

        });

    }

    const formik = useFormik({
        onSubmit: saveProfile,
        validationSchema
    });

    useEffect(() => {
        reloadProfile(
            () => {
                setLoading(false);
            }
        );
    }, []);

    useEffect(() => {
        if (profile) {
            formik.setValues(profile);
        }
    }, [profile]);

    const onCancel = () => {
        reloadProfile();
        navigate("/");
    }

    return (
        <Card className='profile'>
            {loading ? <Loader/> : <><CardHeader onSave={formik.handleSubmit} onCancel={onCancel} icon={userIcon}/>
                {formik.values && <form autoComplete="off">
                    <FormField
                        id="name-input"
                        label="Name"
                        name="name"
                        value={formik.values.name}
                        handleChange={formik.handleChange}
                        handleBlur={formik.handleBlur}
                        touched={formik.touched.name}
                        errors={formik.errors.name}
                    />
                    <FormField
                        id="surname-input"
                        label="Surname"
                        name="surname"
                        value={formik.values.surname}
                        handleChange={formik.handleChange}
                        handleBlur={formik.handleBlur}
                        touched={formik.touched.surname}
                        errors={formik.errors.surname}
                    />
                    <FormField
                        id='avatar-input'
                        label='Avatar'
                        renderInput={false}
                    >
                        <AvatarUpload image={profile.picture_path} setAvatarForm={setAvatarForm}/>
                    </FormField>

                    <FormField
                        id="change-password-input"
                        label="Change password"
                        type="checkbox"
                        name="change_password"
                        value={formik.values.change_password}
                        handleChange={formik.handleChange}
                        handleBlur={formik.handleBlur}
                        touched={formik.touched.change_password}
                        errors={formik.errors.change_password}
                    />
                    {formik.values.change_password &&
                        <>
                            <FormField
                                id="password-input"
                                label="Password"
                                type="password"
                                name="password"
                                value={formik.values.password}
                                handleChange={formik.handleChange}
                                handleBlur={formik.handleBlur}
                                touched={formik.touched.password}
                                errors={formik.errors.password}
                            />
                            <FormField
                                id="confirmPassword-input"
                                label="Confirm password"
                                type="password"
                                name="confirmPassword"
                                value={formik.values.confirmPassword}
                                handleChange={formik.handleChange}
                                handleBlur={formik.handleBlur}
                                touched={formik.touched.confirmPassword}
                                errors={formik.errors.confirmPassword}
                            />
                        </>}
                    {profile.jenkins_username && profile.jenkins_token && formik.values.id &&
                        <FormField
                            id="change-jenkins-input"
                            label="Change jenkins credentials"
                            type="checkbox"
                            name="change_jenkins"
                            value={formik.values.change_jenkins}
                            handleChange={formik.handleChange}
                            handleBlur={formik.handleBlur}
                            touched={formik.touched.change_jenkins}
                            errors={formik.errors.change_jenkins}
                        />}
                    {(!profile.jenkins_username || !profile.jenkins_token || formik.values.change_jenkins) && <>
                        <FormField
                            id="jenkins-username-input"
                            label="Jenkins username"
                            name="jenkins_username"
                            value={formik.values.jenkins_username}
                            handleChange={formik.handleChange}
                            handleBlur={formik.handleBlur}
                            touched={formik.touched.jenkins_username}
                            errors={formik.errors.jenkins_username}
                        />
                        <FormField
                            id="jenkins-token-input"
                            label="Jenkins token"
                            name="jenkins_token"
                            value={formik.values.jenkins_token}
                            handleChange={formik.handleChange}
                            handleBlur={formik.handleBlur}
                            touched={formik.touched.jenkins_token}
                            errors={formik.errors.jenkins_token}
                        />
                    </>
                    }
                    {admin && <FormField
                        renderInput={false}
                    >
                        <Button color="secondary" onClick={resetDatabase}>Reset Database</Button>
                    </FormField>}
                </form>}</>}
        </Card>
    )

}

export default Profile;