import {Route, Routes, useNavigate} from 'react-router-dom';
import Login from "././pages/./login/Login";
import ProtectRoute from "./common/./protectRoute/ProtectRoute";

import Logout from "././pages/./login/Logout";
import Navbar from "./common/./navbar/Navbar";
import Users from "././pages/./users/Users";
import User from "././pages/./users/User";
import Jobs from "././pages/./jobs/Jobs";
import Job from "././pages/./jobs/Job";
import Builds from "././pages/./builds/Builds";
import Build from "././pages/./builds/Build";
import Profile from "././pages/./profile/Profile";
import {useEffect} from "react";
import './App.scss';
import VersionSnackbar from "./common/versionSnackbar/VersionSnackbar";
import {useSelector} from "react-redux";

function App() {

    const token = localStorage.getItem('token')
    const navigate = useNavigate();
    const {appVersionMismatch, appVersion} = useSelector(state => state.app);

    useEffect(() => {
        if (!token) {
            navigate("/login")
        }
    }, [token]);

    return (
        <div className="app">
            <Navbar/>
            {appVersion && appVersionMismatch && <VersionSnackbar/>}
            <ProtectRoute>
                <Routes>
                    <Route path="/login" element={<Login/>}/>
                    <Route path="/logout" element={<Logout/>}/>
                    <Route path="/users" element={<Users/>}/>
                    <Route path="/user/:id?" element={<User/>}/>
                    <Route path="/jobs" element={<Jobs/>}/>
                    <Route path="/job/:slug?" element={<Job/>}/>
                    <Route path="/builds/:jobSlug/:tag?" element={<Builds/>}/>
                    <Route path="/build/:jobSlug/:tag/:id" element={<Build/>}/>
                    <Route path="/profile" element={<Profile/>}/>
                </Routes>
            </ProtectRoute>
            {appVersion && <div className="version">
                v. {appVersion}
            </div>}
        </div>
    );
}

export default App;
