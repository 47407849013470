import React from "react";
import Button from "./Button";
import "./AddNewListItem.scss";


const AddNewListItem = ({onAdd}) => {
    return (
        <Button className='add-new-list-item' onClick={onAdd}>+ Add New</Button>
    );
}

export default AddNewListItem;