import {Navigate, useLocation} from 'react-router-dom';
import {useContextProvider} from "../../context/ContextProvider/ContextProvider";

const ProtectRoute = ({children}) => {
    const location = useLocation();
    const {isAuthenticated} = useContextProvider();
    const admin = localStorage.getItem('admin');
    const forbiddenForUser = ['/users', '/user', '/user/', '/job', '/job/'];

    if (!isAuthenticated && location.pathname !== '/login') {
        return <Navigate to="/login" replace/>;
    }

    if (isAuthenticated && (location.pathname === '/login' || location.pathname === '/')) {
        if (admin === 'true') {
            console.log('Admin authenticated');
            return <Navigate to="/users" replace/>;
        }
        return <Navigate to="/jobs" replace/>;
    }


    if (isAuthenticated && admin === 'false' && forbiddenForUser.includes(location.pathname)) {
        return <Navigate to="/builds" replace/>;
    }


    return children;
};

export default ProtectRoute;
