export const selectStyles = {
    control: (baseStyles, state) => ({
        ...baseStyles,
        color: "#6E7180",


    }),
    option: (baseStyles, {data, isDisabled, isFocused, isSelected}) => ({
        ...baseStyles,
        color: "#6E7180",
        backgroundColor: isDisabled
            ? undefined
            : isSelected
                ? "#F4F5F7"
                : isFocused
                    ? 'rgba(0,146,83,0.25)'
                    : undefined,

    }),

    singleValue: (baseStyles, state) => ({
        ...baseStyles,
        color: "#6E7180",

    }),
    multiValue: (baseStyles, state) => ({
        ...baseStyles,
        color: "#6E7180",
    }),
    multiValueLabel: (baseStyles, state) => ({
        ...baseStyles,
        color: "#009253",
        backgroundColor: "#c6e8d9",
    }),
    multiValueRemove: (styles, { data }) => ({
        ...styles,
        color: "#009253",
        backgroundColor: '#c6e8d9',
        ':hover': {
            backgroundColor: '#009253',
            color: 'white',
        },
    }),
};