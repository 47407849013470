import React, {useEffect, useState} from "react";
import * as diff from "diff";
import * as Diff2Html from "diff2html";
import "diff2html/bundles/css/diff2html.min.css";

const TextDiff = ({fileName, fileActual, fileExpected}) => {
    const [diffHtml, setDiffHtml] = useState("");

    const calculateDiff = () => {
        return diff.createPatch(
            fileName,
            fileExpected,
            fileActual,
        )
    };

    const handleDiff = () => {
        const patch = calculateDiff();
        const hasChanges = patch.split('\n').slice(4).some(line => line.startsWith('+') || line.startsWith('-'));
        if (!hasChanges) {
            return;
        }
        const diff = Diff2Html.html(patch, {
                inputFormat: "text",
                drawFileList: false,
                fileListToggle: false,
                fileListStartVisible: false,
                fileContentToggle: false,
                matching: "lines",
                outputFormat: "side-by-side",
                synchronisedScroll: true,
                highlight: true,
            renderNothingWhenEmpty: false,
            rawTemplates: {
                "file-summary-wrapper": "<div class='d2h-file-summary-wrapper'><div class='d2h-file-summary'>{{.}}</div></div>",
            }
            }
        );
        setDiffHtml(diff);
    };

    useEffect(() => {
        handleDiff();
    }, []);


    return <div dangerouslySetInnerHTML={{__html: diffHtml}}/>

}


export default TextDiff;
