import React from "react";
import './TagsFilter.scss';
import {reverseColor} from "../../common/utils/utils";


const TagsFilter = ({tags, setFilter, filter}) => {

    if (tags.length === 1 && tags[0].name === "Untagged") {
        return null;
    }
    return (
        <div className="tags-filter">
            {tags.length > 0 && tags.map(tag => (
                <div className="tag"
                     key={tag.id}
                     onClick={() => setFilter(tag.name)}
                     style={{
                         color: filter === tag.name && reverseColor(tag.color),
                         backgroundColor: filter === tag.name && tag.color,
                         cursor: filter === tag.name && "default"
                     }}
                >
                    <div>{tag.name}</div>
                </div>
            ))}
        </div>
    );
}

export default TagsFilter;