import React, {useEffect, useRef, useState} from 'react';
import './Dropdown.scss';
import classnames from "classnames";

const Dropdown = ({children, component, className, arrow}) => {
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);
    const toggleDropdown = () => setIsOpen(!isOpen);

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const handleClickOutside = event => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    return (
        <div className={classnames('dropdown', className)} ref={dropdownRef} onClick={toggleDropdown}>
            {component}
            {arrow && <div className="arrow"></div>}
            {isOpen && (
                <div className='content'>
                    {children}
                </div>
            )}
        </div>
    );
};

export default Dropdown;