import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import './ProfileDropdown.scss';
import * as actions from "./ProfileDropdownActions";
import {userInitials} from "../utils/utils";
import Icon, {logoutIcon, userIcon} from "../icons/Icon";
import Dropdown from "./Dropdown";
import Avatar from "../avatar/Avatar";
import {useContextProvider} from "../../context/ContextProvider/ContextProvider";

const ProfileDropdown = () => {
    const navigate = useNavigate();
    const [avatar, setAvatar] = useState(null);
    const {setIsAuthenticated} = useContextProvider();
    const {profile, setProfile, reloadProfile} = useContextProvider();


    const onOptionClicked = (path) => () => {
        if (path === 'logout') {
            localStorage.removeItem('token');
            localStorage.removeItem('admin');
            setIsAuthenticated(false);
            setProfile(null);
            navigate('/login');

        } else {
            navigate(path);
        }
    };

    useEffect(() => {
        if (!profile) return;
        if (profile.picture_path) {
            actions.downloadAvatar('normal', (data) => {
                setAvatar(URL.createObjectURL(data));
            })
        } else {
            setAvatar(null)
        }

    }, [profile]);

    useEffect(() => {
        reloadProfile();
    }, []);

    return (
        <Dropdown
            className='profile' component={
            <div className='avatar-container'>
                {avatar && <Avatar image={avatar} size='small'/>}
                {!avatar && profile && <div className='initials'>{userInitials(profile.name, profile.surname)}</div>}
            </div>
        }
        >
            <ul className="list">
                <li className='item' onClick={onOptionClicked('/profile')}>
                    <Icon icon={userIcon}/> Profile
                </li>
                <li className='item' onClick={onOptionClicked('logout')}>
                    <Icon icon={logoutIcon}/> Logout
                </li>
            </ul>
        </Dropdown>

    );
}

export default ProfileDropdown;
