import React from "react";
import classnames from "classnames";
import LazyImage from "../lazyImage/LazyImage";
import LazyTextDiff from "../lazyTextDiff/LazyTextDiff";
import "./Step.scss";
import _ from "lodash";

const Step = ({step, openLightbox, scenario}) => {
    const {
        error,
        dataTable,
        imagePathMini,
        imagePathDiffMini,
        imagePathActualMini,
        imagePath,
        imagePathDiff,
        imagePathActual,
        textPath,
        textPathActual,
        textFileName
    } = step;
    const imagesForLightbox = !imagePathDiff ? [{src: imagePath}] : [{src: imagePathActual}, {src: imagePathDiff}, {src: imagePath}];

    return (
        <div className='step'>
            <div className='header'>
                <div className={classnames(_.lowerCase(step.status))}/>
                <p className='keyword'>{step.keyword}</p>
                <p className='title'>{step.text}</p>
            </div>
            {dataTable && (
                <div className='step-table'>
                    <table>
                        <tbody>
                        {dataTable.map(row => (
                            <tr key={row[0]}>
                                <td>{row[0]}</td>
                                <td>{row[1]}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            )}
            {imagePathMini && (
                <div className='images'>
                    {!imagePathDiff ? (
                        <LazyImage src={imagePathMini} onClick={() => openLightbox(imagesForLightbox, 0)}/>
                    ) : (
                        <>
                            <LazyImage src={imagePathActualMini} onClick={() => openLightbox(imagesForLightbox, 0)}
                                       imageLabel='Actual'/>
                            <LazyImage src={imagePathDiffMini} onClick={() => openLightbox(imagesForLightbox, 1)}
                                       imageLabel='Diff'/>
                            <LazyImage src={imagePathMini} onClick={() => openLightbox(imagesForLightbox, 2)}
                                       imageLabel='Expected'/>
                        </>
                    )}
                </div>
            )}
            {textPath && (
                <div className='text-diff'>
                    <LazyTextDiff fileName={textFileName} fileUrlActual={textPathActual} fileUrlExpected={textPath}/>
                </div>
            )}
            {step.status === "FAILED" && <>
                {error && <pre className="error">{error}</pre>}
                <div className="images">
                    <LazyImage src={scenario.imagePathMini} onClick={() => openLightbox([{src: scenario.imagePath}], 0)}
                               imageLabel='After'/>
                </div>
            </>
            }
        </div>
    );
};

export default Step;