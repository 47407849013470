import React, {useEffect, useMemo, useState} from 'react';
import "./Users.scss"
import * as actions from "./UsersActions"
import {useNavigate} from "react-router-dom";
import {userGroupIcon} from "../../common/icons/Icon";
import Table from "../../common/tables/Table";
import CardHeader from "../../containers/card/CardHeader";
import Card from "../../containers/card/Card";
import TableActions from "../../common/buttons/TableActions";

const Users = () => {
    const navigate = useNavigate();
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);

    const refreshList = () => {
        actions.loadUsers({}, (data) => {
            setUsers(data);
            setLoading(false);
        });
    }

    const deleteAction = (user) => {
        actions.deleteUser(user.id, () => refreshList());
    }

    const editAction = (user) => {
        navigate(`/user/${user.id}`)
    }

    const handleAddUser = () => {
        navigate(`/user`)
    }

    useEffect(() => {
        refreshList();
    }, []);

    const columns = useMemo(() => [
        {
            Header: 'Username',
            accessor: 'username',
            Cell: ({row}) => (
                <div className='row-name'>
                    <p>{row.original.username}</p>
                </div>)
        },
        {
            Header: 'Jenkins Username',
            accessor: 'jenkins_username'
        },
        {
            Header: 'Jenkins Token',
            accessor: 'jenkins_token'
        },
        {
            Header: 'Actions',
            Cell: ({row}) => (
                <TableActions row={row.original} name='User' onEdit={editAction} onDelete={deleteAction}/>
            )
        }
    ], [users]);


    return (
        <Card className="users">
            <CardHeader onAdd={handleAddUser} icon={userGroupIcon}/>
            <Table columns={columns} data={users} loading={loading}/>
        </Card>
    );
}

export default Users;
