import './VersionSnackbar.scss';
import {useDispatch} from "react-redux";
import {updateAppVersion} from "../../redux/slices/AppSlice";

const VersionSnackbar = () => {
    const dispatch = useDispatch();

    const refresh = () => {
        dispatch(updateAppVersion(null))
        window.location.reload();
    }

    return <div className='version-snackbar'>
        A new version of Picklepot is available. <div onClick={refresh} className="here"> CLICK HERE </div> to update.
    </div>
}

export default VersionSnackbar;