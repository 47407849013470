import React from "react";
import {useTable} from "react-table";
import './Table.scss';
import Loader from "../loader/Loader";

function Table({columns, data, loading}) {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({
        columns,
        data
    })

    return (
        <> {

            loading ? <Loader/> :
                <div className='table'>
                    <table {...getTableProps()} >
                        <thead>
                        {headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()} className='headers'>
                                {headerGroup.headers.map(column => (
                                    <th {...column.getHeaderProps(
                                        {className: column.className ? column.className : ''}
                                    )}>{column.render('Header')}</th>
                                ))}
                            </tr>
                        ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                        {rows.length > 0 ? rows.map((row, i) => {
                            prepareRow(row)
                            return (
                                <tr {...row.getRowProps()} className='row'>
                                    {row.cells.map(cell => {
                                        return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                    })}
                                </tr>
                            )
                        }) : <tr>
                            <td className='no-data' colSpan={columns.length}>Nothing Found</td>
                        </tr>

                        }
                        </tbody>
                    </table>
                </div>

        }
        </>
    )
}

export default Table;