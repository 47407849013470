import React, {useState} from "react";
import "./Accordion.scss";

const Accordion = ({title, children}) => {
    const [isActive, setIsActive] = useState(false);

    return (
        <div className='accordion'>
            <div className='title' onClick={() => setIsActive(!isActive)}>
                {title}
            </div>
            {isActive && <div className='content'>{children}</div>}
        </div>
    )
}

export default Accordion;