import React, {useState} from "react";
import DeleteModal from "../modals/DeleteModal";
import Icon, {deleteIcon, editIcon, magnifierIcon} from "../icons/Icon";
import Button from "./Button";


const TableActions = ({row, name, onEdit, onDelete, onShow}) => {
    const [toDelete, setToDelete] = useState(null)

    return (
        <div className='actions'>
            {toDelete && <DeleteModal
                isOpen={!!toDelete}
                name={name}
                resource={{...row}}
                onDelete={() => onDelete(row)}
                onClose={() => setToDelete(null)}/>
            }
            {onEdit &&
                <Button size='icon'
                        onClick={() => onEdit(row)
                        }>
                    <Icon icon={editIcon}/>
                </Button>}
            {onShow &&
                <Button size='icon'
                        onClick={() => onShow(row)
                        }>
                    <Icon icon={magnifierIcon}/>
                </Button>}
            {onDelete &&
                <Button
                    color="icon"
                    onClick={() => setToDelete(row)}
                >
                    <Icon icon={deleteIcon}/>
                </Button>}
        </div>
    );
}

export default TableActions;